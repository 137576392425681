import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../components/Alert';
import Loader from '../components/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import idFormatter from '../functions/idFormatter';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { SiMicrosoftexcel } from 'react-icons/si'

import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa'
import { listSubscriptions } from '../actions/saleActions';
import { smsCustomer } from '../actions/customerActions';

function SubscriptionScreen() {
    const [subscriptionOpen, setSubscriptionOpen] = useState(false)
	const[changes, setChanges]= useState([])
    const dispatch = useDispatch()

	const navigate = useNavigate()

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
	

	const subscriptionList = useSelector(state=> state.subscriptionList)
	const {loading: loadingSubscriptions, error: errorSubscriptions, subscriptions} = subscriptionList

	const notifyCustomer = useSelector(state=> state.notifyCustomer)
	const {loading: loadingsms, error: errorsms, response} = notifyCustomer



    

	useEffect(() => {
		window.scrollTo(0,0)
		if(!userInfo){
            navigate('/')
        }else{
			dispatch(listSubscriptions())
		}
		
	}, [dispatch, userInfo, response])

    const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;

	const handleSMS = (id)=>{
		dispatch(smsCustomer(id))
	}

	
    

    const columns = [{
		dataField: '_id',
		text: '#',
        formatter: idFormatter,
		editable: false,
        
	  }, {
		dataField: 'customerName',
		text: 'Customer',
		sort: true,
		sortCaret: sortCaret,
		editable: false
		
	  },{
		dataField:'productName',
		text:'Product',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField:'lastOrderDate',
		text:'Last Order Date',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField:'nextOrderDate',
		text:'Next Order Date',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
        
	  },{
        dataField: 'df1',
        isDummyField: true,
        text: 'Notify',
        classes:'text-center',
        headerClasses:'text-center',
        editable: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button className='notify' onClick={()=>handleSMS(row._id)}> Send SMS </button>
              
            );
          
        }
      }
      ];

	  const sortedSubscription = subscriptions.sort((a, b)=> {return new Date(b.nextOrderDate) - new Date(a.nextOrderDate)})
  return (
    <div className="container px-6">
        
        
        <div className="dash-card w-full min-h-[500px]">
					
					<div className="table-wrapper">
						{response && <Alert variant={'success'}>{response.status_desc }</Alert>}
                    {loadingSubscriptions || loadingsms ?<Loader fullscreen /> :errorSubscriptions || errorsms? <Alert variant={'error'}>{errorSubscriptions || errorsms}</Alert>:''}
                    {(!errorSubscriptions && !loadingSubscriptions) && <ToolkitProvider
                            search
                            selectFilter
                            keyField="_id"
                            data={ sortedSubscription }
                            columns={ columns }
							exportCSV={ {
                                fileName: 'Producers.csv',
                                exportAll:false
                              } }
                            
                            
                            >
						{
							props => (
							<div>
								<div className='flex flex-wrap justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-fuchsia-500">
										Subscriptions
									</div>
									<div className='flex flex-row space-x-2'>
										
										<ExportCSVButton { ...props.csvProps } className="button csv" > <SiMicrosoftexcel /> </ExportCSVButton>
										<SearchBar srText={''} className='w-32' { ...props.searchProps } />
									</div>
									
								</div>

								
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-0 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() }
								
                                 
								/>
							</div>
							)
						}
						</ToolkitProvider>}
					</div>
				</div>
    </div>
  )
}

export default SubscriptionScreen