import React from 'react'
import ReactApexChart from 'react-apexcharts'

function DashChart({options, data, height, width, type}) {
  return (
	
		<ReactApexChart options={options} series={data} type={type} height={height} width={width} />						
	
  )
}

export default DashChart