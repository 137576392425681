import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sendResetOTP,  verifyResetOTP } from '../actions/userActions'
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import PhoneInput from 'react-phone-input-2'


function ResetScreen({setCollapsed}) {
	
    const [phoneNumber, setPhoneNumber] = useState('')
    const [otp, setOtp] = useState('')

	
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch =  useDispatch()
	const redirect = location.search ? location.search.split('=')[1] : '/home'

	const userOTP = useSelector(state=> state.userOTP)
	const {success, error, loading} = userOTP

    const verifyOTP = useSelector(state=> state.verifyOTP)
	const {success: sucessVerify, error:errorVerify, loading:loadingVerify} = verifyOTP

    const userLogin = useSelector(state=> state.userLogin)
	const {userInfo} = userLogin

	useEffect(() => {
		window.scrollTo(0,0)
		setCollapsed(false)
        
        if (userInfo) {
			navigate('/home')
		}
			
		
	}, [success, navigate, userInfo, sucessVerify])

	const onSubmitHandler = (e)=>{
		e.preventDefault()
		dispatch(sendResetOTP(phoneNumber))
	}

    const verify = (e)=>{
		e.preventDefault()
		dispatch(verifyResetOTP(otp))
	}

	return (
		<div className="container">
				
				<div className="w-full md:w-1/3 mx-auto mt-40">
						
						<form className='ml-0 md:-ml-32 rounded-2xl py-6 bg-white shadow-xl' onSubmit={onSubmitHandler}>
							{!success && <>
                                <h2 className='text-xl font-bold text-transparent text-center bg-clip-text bg-gradient-to-tl from-fuchsia-500 to-purple-500'>Welcome Back</h2>
                                <div className="form-group">
                                        <label htmlFor="phoneNumber" className="form-label">Please enter the Phone Number Associated with your account</label>
                                        <PhoneInput
                                            onlyCountries={['ke']}
                                            country='ke'
                                            value={phoneNumber}
                                            onChange={setPhoneNumber}
                                            
                                            
                                        />
                                </div>

                                <div className="px-6 hover:scale-101">
                                    <button type="submit" className='form-submit'>Send OTP</button>
                                    
                                </div>
                            </>}

                            {success && <>
                                <h2 className='text-xl font-bold text-transparent text-center bg-clip-text bg-gradient-to-tl from-fuchsia-500 to-purple-500'>Welcome Back</h2>
                                <div className="form-group">
                                        <label htmlFor="phoneNumber" className="form-label">Please enter the Code Sent to your phone</label>
                                        <input value={otp} onChange={(e)=>setOtp(e.target.value)} required type="text" placeholder='Please Enter OTP code' name="otp" id="otp" className="form-input" />
                                       
                                </div>

                                <div className="px-6 hover:scale-101">
                                    <button onClick={(e)=>verify(e)} className='form-submit'>Verify</button>
                                    
                                </div>
                            </>}

							{(error || errorVerify) && <Alert variant='error'>{error || errorVerify}</Alert>}

						</form>
						{(loading || loadingVerify) && <Loader fullscreen />}
				</div>
		</div>
	)
}

export default ResetScreen