import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomerForm from '../forms/CustomerForm'
import { listCustomers, updateCustomer } from '../actions/customerActions';
import Alert from '../components/Alert';
import Loader from '../components/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import idFormatter from '../functions/idFormatter';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { SiMicrosoftexcel } from 'react-icons/si'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_UPDATE_RESET } from '../constants/customerConstants';


function CustomerScreen() {
    const [customerOpen, setCustomerOpen] = useState(false)
	const[changes, setChanges]= useState([])
    const dispatch = useDispatch()

	const navigate = useNavigate()

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
	

	const customerList = useSelector(state=> state.customerList)
	const {loading: loadingCustomers, error: errorCustomers, customers} = customerList

	const customerCreate = useSelector(state=> state.customerCreate)
	const {success} = customerCreate

	const customerUpdate = useSelector(state => state.customerUpdate)
    const { error: errorUpdate, loading: loadingUpdate, response, success:successUpdate} = customerUpdate

    

	useEffect(() => {
		window.scrollTo(0,0)
		if(!userInfo){
            navigate('/')
        }else{
			dispatch(listCustomers())
		}
		if (successUpdate){
			setChanges([])
			setTimeout(() => {
				dispatch({type: CUSTOMER_UPDATE_RESET})
				
			}, 2000);
		}
	}, [dispatch, success, successUpdate, userInfo])

    const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;
    

    const columns = [{
		dataField: '_id',
		text: '#',
		editable: false,
        formatter: idFormatter,
        
	  },{
		dataField: 'firstName',
		text: 'First Name',
		sort: true,
		sortCaret: sortCaret,
		
	  },{
		dataField: 'lastName',
		text: 'Last Name',
		sort: true,
		sortCaret: sortCaret,
		
	  },{
		dataField:'phoneNumber',
		text:'Contact',
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField:'location',
		text:'Location',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
		
	  },{
		dataField:'gender',
		text:'Gender',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField:'households',
		text:'# of pople in Houseold',
		editable: true,
		sort:true,
		sortCaret: sortCaret,
        
	  }];
  return (
    <div className="container px-10">
        <CustomerForm customerOpen={customerOpen} setCustomerOpen={setCustomerOpen} />
        <div className='flex px-0 pb-4 flex-row items-center justify-between'>
			
			
		</div>

        <div className="mt-6 dash-card w-full min-h-[500px]">
					
					<div className="table-wrapper">
                    {loadingCustomers || loadingUpdate?<Loader fullscreen /> :errorCustomers || errorUpdate? <Alert variant={'error'}>{errorCustomers || errorUpdate}</Alert>:''}
                    {(!errorCustomers && !loadingCustomers) && <ToolkitProvider
                            search
                            selectFilter
                            keyField="_id"
                            data={ customers }
                            columns={ columns }
							exportCSV={ {
                                fileName: 'Producers.csv',
                                exportAll:false
                              } }
                            
                            
                            >
						{
							props => (
							<div>
								<div className='flex flex-wrap justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-fuchsia-500">
										Customers
									</div>
									<div className='flex flex-row space-x-2'>
									<button disabled={changes.length===0} onClick={()=>dispatch(updateCustomer(changes))}>Save Changes</button>
										<ExportCSVButton { ...props.csvProps } className="button csv" > <SiMicrosoftexcel /> </ExportCSVButton>
										<SearchBar srText={''} className='w-32' { ...props.searchProps } />
									</div>
									
								</div>
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-0 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() }
								cellEdit={ cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: (oldValue, newValue, row, column) => { setChanges([...changes, {value: newValue, _id: row._id, dataField: column.dataField}]); }
									}) } 
                                 
								
                                 
								/>
							</div>
							)
						}
						</ToolkitProvider>}
					</div>
				</div>
    </div>
  )
}

export default CustomerScreen