import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import Select from 'react-select'
import OutsideAlerter from "../functions/listen-for-outside-clicks";
import PhoneInput from 'react-phone-input-2'
import { createProduct } from '../actions/productActions';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
function ProductForm({productOpen, setProductOpen}) {
    const dispatch = useDispatch()
	

    const productCreate = useSelector(state=> state.productCreate)
	const {success, loading:loadingCreate, error:errorCreate} = productCreate

	const [name, setName] = useState('')
    const [size, setSize] = useState('')
	const [type, setType] = useState('')
    const [price, setPrice] = useState(0)
    const [weight, setWeight] = useState('')
    const [color, setColor] = useState('')
    const [units, setUnits] = useState('')
    const [weightUnits, setWeightUnits] = useState('')
    const [material, setMaterial] = useState('')
    
	
    
	

	const productSubmitHandler = (e)=>{
		e.preventDefault()
        dispatch(createProduct({name, type, price, color, size:size+' '+units, weight: weight +' '+weightUnits, material}))
		
	} 
	
	useEffect(() => {
	  

      if (success) {
    
        
        setName('')
        setSize('')
        setType('')
        setPrice('')
        setWeight('')
        setColor('')
        setUnits('')
        setWeightUnits('')
        setProductOpen(false)
      }
      
	}, [dispatch, success])
	
	
	
  return (
    <div className={`modal-container ${productOpen ? 'flex' : 'hidden'}`}>
            {productOpen && <OutsideAlerter setOpen={setProductOpen}>
			    <div className="modal-content small">
				<h2 className="modal-header">
					Add product
				</h2>
				<div className="modal-body">
					<form className='flex flex-wrap' onSubmit={productSubmitHandler}>
                        						
                            <div className="form-group mt-4">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input value={name} onChange={(e)=> setName(e.target.value)} required type="text" placeholder=' Name' name="name" id="name" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="price" classPhone="form-label">Product Price </label>
                                <input value={price} onChange={(e)=> setPrice(e.target.value)} required type="number" placeholder='Price' name="price" id="price" className="form-input" />
                            </div>


                            {/* <div className="form-group">
                                <label htmlFor="productCategory" className="form-label">Category</label>
                                <select value={productCategory} onChange={(e)=> setProductCategory(e.target.value)} required  name="productCategory" id="productCategory" className="form-input" >
                                    <option value=''>--Select product Category--</option>
                                    <option value='Producer'>Producer</option>
                                    <option value='Distributor'>Distributor</option>
                                    <option value='Stockist'>Stockist</option>
                                    <option value='Installer'>Installer</option>
                                </select>
                            </div> */}

                            

                            

                            

                            <div className="form-group">
                                <label htmlFor="size" className="form-label">Product Size</label>
                                <div className="flex flex-row gap-8">
                                    <input value={size}  onChange={(e)=> setSize(e.target.value)} required type="number" placeholder='Size' name="size" id="size" className="form-input" />
                                    <select className="form-input" value={units} onChange={(e)=>setUnits(e.target.value)} name="units" id="units">
                                        <option disabled value="">-- Select Units --</option>
                                        <option value="g">Grams</option>
                                        <option value="kg">Kilograms</option>
                                        <option value="l">Litres</option>
                                        <option value="ml">Milllilitres</option>
                                        <option value="m">Metres</option>
                                        <option value="ft">Feet</option>
                                        <option value="in">Inches</option>
                                    </select>
                                </div>
                                
                            </div>

                            {/* <div className="form-group">
                                <label htmlFor="weight" classPhone="form-label">Product Weight </label>
                                <div className="flex flex-row gap-8">
                                    <input value={weight} onChange={(e)=> setWeight(e.target.value)} type="number" placeholder='weight' name="weight" id="weight" className="form-input" />
                                    <input value={weightUnits} onChange={(e)=> setWeightUnits(e.target.value)} type="text" placeholder='units' name="weightUnits" id="weightUnits" className="form-input" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="color" classPhone="form-label">Product Color </label>
                                <input value={color} onChange={(e)=> setColor(e.target.value)} type="text" placeholder='Color' name="color" id="color" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="material" classPhone="form-label">Product Material </label>
                                <input value={material} onChange={(e)=> setMaterial(e.target.value)} type="text" placeholder='Material' name="material" id="material" className="form-input" />
                            </div> */}
                            
                            <div className="w-full px-8 hover:scale-101">
                                <button type="submit" className='form-submit'>Save</button>
                            </div>

                        {errorCreate && <Alert variant='error' >{errorCreate}</Alert>}
						
					</form>
				</div>
			    </div>
            </OutsideAlerter>}
            {loadingCreate && <Loader fullscreen />}
		</div>
  )
}

export default ProductForm