export const STOCK_LIST_REQUEST = 'STOCK_LIST_REQUEST'
export const STOCK_LIST_SUCCESS = 'STOCK_LIST_SUCCESS'
export const STOCK_LIST_FAIL = 'STOCK_LIST_FAIL'


export const STOCK_DETAILS_REQUEST = 'STOCK_DETAILS_REQUEST'
export const STOCK_DETAILS_SUCCESS = 'STOCK_DETAILS_SUCCESS'
export const STOCK_DETAILS_FAIL = 'STOCK_DETAILS_FAIL'


export const STOCK_DELETE_REQUEST = 'STOCK_DELETE_REQUEST'
export const STOCK_DELETE_SUCCESS = 'STOCK_DELETE_SUCCESS'
export const STOCK_DELETE_FAIL = 'STOCK_DELETE_FAIL'

export const STOCK_CREATE_REQUEST = 'STOCK_CREATE_REQUEST'
export const STOCK_CREATE_SUCCESS = 'STOCK_CREATE_SUCCESS'
export const STOCK_CREATE_FAIL = 'STOCK_CREATE_FAIL'
export const STOCK_CREATE_RESET = 'STOCK_CREATE_RESET'


export const STOCK_UPDATE_REQUEST = 'STOCK_UPDATE_REQUEST'
export const STOCK_UPDATE_SUCCESS = 'STOCK_UPDATE_SUCCESS'
export const STOCK_UPDATE_FAIL = 'STOCK_UPDATE_FAIL'
export const STOCK_UPDATE_RESET = 'STOCK_UPDATE_RESET'