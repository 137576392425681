import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { register } from '../actions/userActions'
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import PhoneInput from 'react-phone-input-2'
import axios from 'axios';


function RegisterScreen({setCollapsed}) {
	const [first_name, setFirst_name] = useState('')
	const [last_name, setLast_name] = useState('')
	const [email, setEmail] = useState('');
	const [company, setCompany] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [loadingPlaces, setLoadingPlaces] = useState(false)
	const [location, setLocation] = useState('')
    const [places, setPlaces] = useState([])
    const [placeId, setPlaceId] = useState('')
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('')

	
	const navigate = useNavigate()
	const ulocation = useLocation()
	const dispatch =  useDispatch()
	const redirect = ulocation.search ? ulocation.search.split('=')[1] : '/home'

	const userRegister = useSelector(state=> state.userRegister)
	const {userInfo, error, loading} = userRegister

	useEffect(() => {
		window.scrollTo(0,0)
		setCollapsed(false)	
			
		if (userInfo) {
			navigate(redirect)
		}
	}, [navigate, userInfo, redirect])

	const onSubmitHandler = (e)=>{
		e.preventDefault()

		if (placeId=='') {
			window.alert("Please select a valid location")
			return false;
		}
		if (phoneNumber=='') {
			window.alert("Please Enter a valid Phone Number")
			return false;
		}
		dispatch(register({email, password,first_name, last_name, company, phoneNumber, location, placeId}))
	}

	const getPlaces = (input) =>{
        
        setLocation(input)
        
        
        let config = {
        method: 'post',
        url: '/api/customers/places/',
        data:{
            input:input,
        },
        headers: { 
            'Accept': 'application/json'
        }
        };
        setLoadingPlaces(true)
        axios(config)
        .then((response) => {
        
        setLoadingPlaces(false)
        setPlaces(response.data)
        })
        .catch((error) => {
        console.log(error);
        });

    }

	return (
		<div className="container">
				
				<div className="w-full md:w-1/3 mx-auto mt-4">
						
						<form className='ml-0 md:-ml-32 rounded-2xl py-6 bg-white shadow-xl' onSubmit={onSubmitHandler}>
						
						{error && <Alert variant='error'>{error}</Alert>}

							<h2 className='text-xl font-bold text-transparent text-center bg-clip-text bg-gradient-to-tl from-fuchsia-500 to-purple-500'>Welcome</h2>
							
							<div className="form-group">
                                <label htmlFor="first_name" className="form-label">Full Name</label>
                                <div className="flex flex-row gap-8">
                                    <input value={first_name}  onChange={(e)=> setFirst_name(e.target.value)} required type="text" placeholder='First Name' name="first_name" id="first_name" className="form-input" />
                                    <input value={last_name}  onChange={(e)=> setLast_name(e.target.value)} required type="text" placeholder='Last Name' name="last_name" id="last_name" className="form-input" />
                                </div>
                                
                            </div>
							<div className="form-group">
								<label htmlFor="email" className="form-label">Email</label>
								<input value={email} onChange={(e)=>setEmail(e.target.value)} required type="email" placeholder='Please Enter your email address' name="email" id="email" className="form-input" />
							</div>
							<div className="form-group">
                                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                    <PhoneInput
                                        onlyCountries={['ke','ug','sn']}
                                        country='ke'
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
										
                                        
                                    />
                                </div>
							
							<div className="form-group">
								<label htmlFor="company" className="form-label">Company Name</label>
								<input value={company} onChange={(e)=>setCompany(e.target.value)} required type="text" placeholder='Please Enter your company name' name="company" id="company" className="form-input" />
							</div>

							<div className="form-group">
                                <label htmlFor="location" className="form-label">Location</label>
                                <input value={location}  onChange={(e)=> getPlaces(e.target.value)} required type="text" placeholder='Location' name="location" id="location" className="form-input" />
                                <ul className="bg-white rounded-lg border border-gray-200 w-full text-gray-900">
                                    
                                    {places.map(p=>
                                        <li key={p._id} onClick={()=>{setLocation(p.address); setPlaces([]); setPlaceId(p._id)}} className="hover:bg-fuchsia-200 cursor-pointer px-6 py-2 border-b border-gray-200 w-full">{p.address}</li>
                                    )}
                                </ul>
                            </div>

							<div className="form-group">
								<label htmlFor="password" className="form-label">Password</label>
								<input value={password} onChange={(e)=>setPassword(e.target.value)} required type="password" placeholder='Please Enter your password' name="password" id="password" className="form-input" />
							</div>

							<div className="form-group">
								<label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
								<input value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} required type="password" placeholder='Please Confirm your password' name="confirmPassword" id="confirmPassword" className="form-input" />
							</div>
							


							

							<div className="px-6 hover:scale-101">
								<button type="submit" className='form-submit'>Register</button>
								
							</div>
							
							

						</form>
						{loading && <Loader fullscreen />}
				</div>
		</div>
	)
}

export default RegisterScreen