export const SALE_LIST_REQUEST = 'SALE_LIST_REQUEST'
export const SALE_LIST_SUCCESS = 'SALE_LIST_SUCCESS'
export const SALE_LIST_FAIL = 'SALE_LIST_FAIL'

export const SALE_CREATE_REQUEST = 'SALE_CREATE_REQUEST'
export const SALE_CREATE_SUCCESS = 'SALE_CREATE_SUCCESS'
export const SALE_CREATE_FAIL = 'SALE_CREATE_FAIL'
export const SALE_CREATE_RESET = 'SALE_CREATE_RESET'

export const SALES_TREND_REQUEST = 'SALES_TREND_REQUEST'
export const SALES_TREND_SUCCESS = 'SALES_TREND_SUCCESS'
export const SALES_TREND_FAIL = 'SALES_TREND_FAIL'

export const SALES_PER_PRODUCT_REQUEST = 'SALES_PER_PRODUCT_REQUEST'
export const SALES_PER_PRODUCT_SUCCESS = 'SALES_PER_PRODUCT_SUCCESS'
export const SALES_PER_PRODUCT_FAIL = 'SALES_PER_PRODUCT_FAIL'

export const SALES_PER_CUSTOMER_REQUEST = 'SALES_PER_CUSTOMER_REQUEST'
export const SALES_PER_CUSTOMER_SUCCESS = 'SALES_PER_CUSTOMER_SUCCESS'
export const SALES_PER_CUSTOMER_FAIL = 'SALES_PER_CUSTOMER_FAIL'

export const PRODUCT_SALES_TREND_REQUEST = 'PRODUCT_SALES_TREND_REQUEST'
export const PRODUCT_SALES_TREND_SUCCESS = 'PRODUCT_SALES_TREND_SUCCESS'
export const PRODUCT_SALES_TREND_FAIL = 'PRODUCT_SALES_TREND_FAIL'

export const SALE_DELETE_REQUEST = 'SALE_DELETE_REQUEST'
export const SALE_DELETE_SUCCESS = 'SALE_DELETE_SUCCESS'
export const SALE_DELETE_FAIL = 'SALE_DELETE_FAIL'

export const SALE_UPDATE_REQUEST = 'SALE_UPDATE_REQUEST'
export const SALE_UPDATE_SUCCESS = 'SALE_UPDATE_SUCCESS'
export const SALE_UPDATE_FAIL = 'SALE_UPDATE_FAIL'
export const SALE_UPDATE_RESET = 'SALE_UPDATE_RESET'

export const SALES_PER_CLUSTER_REQUEST = 'SALES_PER_CLUSTER_REQUEST'
export const SALES_PER_CLUSTER_SUCCESS = 'SALES_PER_CLUSTER_SUCCESS'
export const SALES_PER_CLUSTER_FAIL = 'SALES_PER_CLUSTER_FAIL'

export const SALES_PER_LMETYPE_REQUEST = 'SALES_PER_LMETYPE_REQUEST'
export const SALES_PER_LMETYPE_SUCCESS = 'SALES_PER_LMETYPE_SUCCESS'
export const SALES_PER_LMETYPE_FAIL = 'SALES_PER_LMETYPE_FAIL'

export const SALES_PER_COUNTY_REQUEST = 'SALES_PER_COUNTY_REQUEST'
export const SALES_PER_COUNTY_SUCCESS = 'SALES_PER_COUNTY_SUCCESS'
export const SALES_PER_COUNTY_FAIL = 'SALES_PER_COUNTY_FAIL'

export const SUBSCRIPTION_LIST_REQUEST = 'SUBSCRIPTION_LIST_REQUEST'
export const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS'
export const SUBSCRIPTION_LIST_FAIL = 'SUBSCRIPTION_LIST_FAIL'