export const PRODUCT_TYPE_DROPDOWN_REQUEST = 'PRODUCT_TYPE_DROPDOWN_REQUEST'
export const PRODUCT_TYPE_DROPDOWN_SUCCESS = 'PRODUCT_TYPE_DROPDOWN_SUCCESS'
export const PRODUCT_TYPE_DROPDOWN_FAIL = 'PRODUCT_TYPE_DROPDOWN_FAIL'

export const CUSTOMER_TYPE_DROPDOWN_REQUEST = 'CUSTOMER_TYPE_DROPDOWN_REQUEST'
export const CUSTOMER_TYPE_DROPDOWN_SUCCESS = 'CUSTOMER_TYPE_DROPDOWN_SUCCESS'
export const CUSTOMER_TYPE_DROPDOWN_FAIL = 'CUSTOMER_TYPE_DROPDOWN_FAIL'

export const COUNTY_DROPDOWN_REQUEST = 'COUNTY_DROPDOWN_REQUEST'
export const COUNTY_DROPDOWN_SUCCESS = 'COUNTY_DROPDOWN_SUCCESS'
export const COUNTY_DROPDOWN_FAIL = 'COUNTY_DROPDOWN_FAIL'

export const PRODUCT_DROPDOWN_REQUEST = 'PRODUCT_DROPDOWN_REQUEST'
export const PRODUCT_DROPDOWN_SUCCESS = 'PRODUCT_DROPDOWN_SUCCESS'
export const PRODUCT_DROPDOWN_FAIL = 'PRODUCT_DROPDOWN_FAIL'

export const CUSTOMER_DROPDOWN_REQUEST = 'CUSTOMER_DROPDOWN_REQUEST'
export const CUSTOMER_DROPDOWN_SUCCESS = 'CUSTOMER_DROPDOWN_SUCCESS'
export const CUSTOMER_DROPDOWN_FAIL = 'CUSTOMER_DROPDOWN_FAIL'
