import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getCountyDropdown } from '../actions/dropdownActions'
import OutsideAlerter from "../functions/listen-for-outside-clicks";
import PhoneInput from 'react-phone-input-2'
import { createSale } from '../actions/saleActions';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import { listProducts } from '../actions/productActions';
import { listSales } from '../actions/saleActions';
import axios from 'axios';
import { listCustomers } from '../actions/customerActions';


function SaleForm({saleOpen, setSaleOpen}) {
    const dispatch = useDispatch()
	const countyDropdown = useSelector(state=> state.countyDropdown)
	const {wards, counties, loading:loadingCounties, error:errorCounties} = countyDropdown

    const saleCreate = useSelector(state=> state.saleCreate)
	const {success, loading:loadingCreate, error:errorCreate} = saleCreate

    const productList = useSelector(state=> state.productList)
    const {error:errorProducts, loading:loadingProducts, products} = productList

    const saleList = useSelector(state=> state.saleList)
	const {loading: loadingSales, error: errorSales, sales} = saleList

    const customerList = useSelector(state=> state.customerList)
	const {loading: loadingCustomers, error: errorCustomers, customers} = customerList

	const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
	const [quantity, setQuantity] = useState('')
    const [unitPrice, setUnitPrice] = useState('')
    const [totalPrice, settotalPrice] = useState('')
    const [location, setLocation] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [product, setProduct] = useState('')
	const [date, setDate] = useState('')
	const [loading, setLoading] = useState(false)
    const [places, setPlaces] = useState([])
    const [placeId, setPlaceId] = useState('')
    const [IDNumber, setIDNumber] = useState('')
    const [households, setHouseholds] = useState('')
    const [gender, setGender] = useState('')
    const [incomeLevel, setIncomeLevel] = useState('')
    const [educationLevel, setEducationLevel] = useState('')
    const [type, setType] = useState('')
    const [renewalValue, setRenewalValue] = useState('')
    const [renewalUnits, setRenewalUnits] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [customer, setCustomer] = useState('')
    const [notify, setNotify] = useState(false)

    

    const handleProductChange = (newValue, actionMeta) => {
        

        if (newValue){
            setUnitPrice(products.find(x=>x._id==newValue.value).price)
            setProduct(newValue.value)
        }else{
            setProduct('')
        }
        
    };

    const handleCustomerChange = (newValue, actionMeta) => {
        

        if (newValue){
            setCustomer(newValue.value)
        }else{
            setCustomer('')
        }
        
    };

    

	

	const SaleSubmitHandler = (e)=>{
		e.preventDefault()
        dispatch(createSale({notify, customer, firstName, lastName, email, phoneNumber, IDNumber, location, placeId, gender, households, maritalStatus, educationLevel, date, quantity, product, renewalPeriod: renewalValue, renewalUnits, unitPrice, totalPrice:unitPrice*quantity}))
		
	} 
	
	useEffect(() => {
	  
      dispatch(listProducts())
      dispatch(listSales())
      dispatch(listCustomers())

      if (success) {
        setDate('')
        setQuantity(1)
        setFirstName('') 
        setIDNumber('')
        setIncomeLevel('')
        setEducationLevel('')
        setHouseholds('')
        setGender('')
        setType('')
        setPhoneNumber('')
        setLocation('')
        setMaritalStatus('')
        setSaleOpen(false)
        setProduct('')
        setUnitPrice(1)
        settotalPrice(1)
        setPlaceId('')
        setNotify(false)
      }
      
	}, [dispatch, success, setSaleOpen])
	
	const getPlaces = (input) =>{
        
        setLocation(input)
        
        
        let config = {
        method: 'post',
        url: '/api/customers/places/',
        data:{
            input:input,
        },
        headers: { 
            'Accept': 'application/json'
        }
        };
        setLoading(true)
        axios(config)
        .then((response) => {
        
        setLoading(false)
        setPlaces(response.data)
        })
        .catch((error) => {
        console.log(error);
        });

    }
    const today = new Date()
  return (
    <div className={`modal-container ${saleOpen ? 'flex' : 'hidden'}`}>
            {saleOpen && <OutsideAlerter setOpen={setSaleOpen}>
			    <div className="modal-content">
				<h2 className="modal-header">
					Add Sale
				</h2>
				<div className="modal-body">
					<form className='flex flex-wrap py-4' onSubmit={SaleSubmitHandler}>
                        <div className="col-2">
                            <h3 className="text-fuchsia-500 text-center font-normal">Sale Details</h3>
                            <div className="form-group">
                                <label htmlFor="date" className="form-label">Date</label>
                                <input value={date} onChange={(e)=> setDate(e.target.value)} max={`${today.getFullYear()}-${today.getMonth()<9?0:''}${today.getMonth()+1}-${today.getDate()}`} required type="date" name="date" id="date" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="product" className="form-label">Product</label>
                                <Select id='product' required isClearable isSearchable options={products.map((item)=> { return {label:item.name + ' ' + item.size, value:item._id}} )} onChange={handleProductChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="quantity" className="form-label">Quantity</label>
                                <input value={quantity} onChange={(e)=> setQuantity(e.target.value)} required type="number" placeholder='Quantity' name="quantity" id="quantity" className="form-input" />
                            </div>
                            

                            <div className="form-group">
                                <label htmlFor="unitPrice" className="form-label">Unit Price</label>
                                <input value={unitPrice} onChange={(e)=> setUnitPrice(e.target.value)} required type="number" placeholder='Price' name="unitPrice" id="unitPrice" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="totalPrice" className="form-label">Total Price</label>
                                <input value={unitPrice*quantity} onChange={(e)=> settotalPrice(e.target.value)} disabled required type="number"  name="totalPrice" id="totalPrice" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="renewalValue" className="form-label">Renewal Period</label>
                                <div className="flex flex-row gap-8">
                                    <input value={renewalValue}  onChange={(e)=> setRenewalValue(e.target.value)} required type="number" placeholder='Period' name="renewalValue" id="renewalValue" className="form-input" />
                                    <select className="form-input" value={renewalUnits} onChange={(e)=>setRenewalUnits(e.target.value)} name="renewalUnits" id="renewalUnits">
                                        <option disabled value="">-- Select Days/Months/Years --</option>
                                        <option value="Days">Days</option>
                                        <option value="Weeks">Weeks</option>
                                        <option value="Months">Months</option>
                                        <option value="Years">Years</option>
                                    </select>
                                </div>
                                
                            </div>

                            

                            
                            
                            

                        </div>



                        <div className="col-2">
                            <h3 className="text-fuchsia-700 text-center font-normal">Customer Details</h3>
                            
                            <div className="form-group">
                                <label htmlFor="customer" className="form-label">Select Customer</label>
                                <Select defaultValue='' id='customer' required isClearable isSearchable options={[...[{label:'New', value:''}],...(customers.map((item)=> { return {label:item.firstName+' '+item.lastName, value:item._id}} ))]} onChange={handleCustomerChange} />
                            </div>
                            {customer!=='' && <div className="flex gap-2 px-9 my-3 items-center">
                                    
                                    <input type="checkbox" className='checkbox' checked={notify} onChange={(e)=>setNotify(e.target.checked)} />
                                    <label htmlFor="notify" className="form-label">Notify Customer</label>
                                </div>}
                           {customer==='' && <>
                            <div className="form-group">
                                <label htmlFor="firstName" className="form-label">Customer Name</label>
                                <div className="flex flex-row gap-8">
                                    <input value={firstName}  onChange={(e)=> setFirstName(e.target.value)} required type="text" placeholder='First Name' name="firstName" id="firstName" className="form-input" />
                                    <input value={lastName}  onChange={(e)=> setLastName(e.target.value)} required type="text" placeholder='Last Name' name="lastName" id="lastName" className="form-input" />
                                </div>
                                
                            </div>

                            <div className="form-group">
                                <label htmlFor="email" className="form-label">Email (Optional)</label>
                                <input value={email}  onChange={(e)=> setEmail(e.target.value)} type="email" placeholder='Email' name="email" id="email" className="form-input" />
                            </div>
                            
                            {/* <div className="flex flex-row gap-8"> */}
                                <div className="form-group">
                                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                    <PhoneInput
                                        onlyCountries={['ke','ug','sn']}
                                        country='ke'
                                        value={phoneNumber}
                                        onChange={setPhoneNumber} 
                                        
                                    />
                                </div>

                                <div className="flex gap-2 px-9 -mt-2 mb-3 items-center">
                                    
                                    <input type="checkbox" className='checkbox' checked={notify} onChange={(e)=>setNotify(e.target.checked)} />
                                    <label htmlFor="notify" className="form-label">Notify Customer</label>
                                </div>

                                {/* <div className="form-group pl-0">
                                    <label htmlFor="IDNumber" className="form-label">ID Number</label>
                                    <input value={IDNumber}  onChange={(e)=> setIDNumber(e.target.value)} required type="number" placeholder='ID Number' name="IDNumber" id="IDNumber" className="form-input" />
                                </div>
                            


                            </div> */}

                            
                            

                            <div className="form-group">
                                <label htmlFor="location" className="form-label">Location</label>
                                <input value={location}  onChange={(e)=> getPlaces(e.target.value)} required type="text" placeholder='Location' name="location" id="location" className="form-input" />
                                <ul className="bg-white rounded-lg border border-gray-200 w-full text-gray-900">
                                    
                                    {places.map(p=>
                                        <li key={p._id} onClick={()=>{setLocation(p.address); setPlaces([]); setPlaceId(p._id)}} className="hover:bg-fuchsia-200 cursor-pointer px-6 py-2 border-b border-gray-200 w-full">{p.address}</li>
                                    )}
                                </ul>
                            </div>

                            <div className="flex flex-row gap-8">
                                <div className="form-group pr-0">
                                    <label htmlFor="phoneNumber" classPhone="form-label">Gender</label>
                                    <select className="form-input" value={gender} onChange={(e)=>setGender(e.target.value)} name="gender" id="gender">
                                        <option disabled value="">--Select Gender--</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>

                                <div className="form-group pl-0">
                                    <label htmlFor="households" className="form-label">Household Size (Optional)</label>
                                    <input value={households}  onChange={(e)=> setHouseholds(e.target.value)} type="number" placeholder='No of people in household' name="households" id="households" className="form-input" />
                                </div>
                            </div>
                            
                            {/* <div className="flex flex-row gap-8">
                                <div className="form-group pr-0">
                                        <label htmlFor="maritalStatus" classPhone="form-label">Marital Status</label>
                                        <select className="form-input" value={maritalStatus} onChange={(e)=>setMaritalStatus(e.target.value)} name="maritalStatus" id="maritalStatus">
                                            <option disabled value="">--Select Marital Status--</option>
                                            <option value="Single">Single</option>
                                            <option value="Married">Married</option>
                                        </select>
                                </div>

                                <div className="form-group pl-0">
                                        <label htmlFor="maritalStatus" classPhone="form-label">Education Level</label>
                                        <select className="form-input" value={educationLevel} onChange={(e)=>setEducationLevel(e.target.value)} name="educationLevel" id="educationLevel">
                                            <option disabled value="">--Select Education Level--</option>
                                            <option value="Primary">Primary</option>
                                            <option value="Secondary">Secondary</option>
                                            <option value="University">University</option>
                                        </select>
                                </div>
                            </div> */}
                            </>}
                        </div>


						

						
						
						<div className="w-full px-10 hover:scale-101">
							<button type="submit" className='form-submit'>Save</button>
						</div>

                        {(errorCustomers || errorCreate|| errorProducts || errorSales) && <Alert variant='error' >{errorCustomers || errorCreate|| errorProducts || errorCounties || errorSales}</Alert>}
						
					</form>
				</div>
			    </div>
            </OutsideAlerter>}
            {(loadingCreate || loadingProducts || loadingCounties|| loadingSales || loadingCustomers) && <Loader fullscreen />}
		</div>
  )
}

export default SaleForm